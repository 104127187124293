$font-family-1: 'FuturaBT-Light', sans-serif;
$font-family-2: 'Augustus', serif;

// Weight
$font-weight-base: 300;
$font-weight-bold: 700;

// Size
$font-size-base: 1rem;

// Spacing
$font-spacing-base: 0rem;

// Line Height
$line-height-base: 1.7;
$line-height-title: 1.5;

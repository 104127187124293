

.policy__content{
  margin-top: slamp(25px, 40px);
  text-align: left;
  max-width: 900px;
  ul{
    list-style: disc;
    margin-left: 40px;
    margin-top: slamp(15px, 30px);
  }
  p{
    margin-top: slamp(15px, 30px);
  }
  a{
    @include underline-link();
    display: inline-block;
  }
}

.composable__title{
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
}

.composable__text{
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  a{
    @include underline-link;
  }

  ul{
    list-style: disc;
    margin-left: 0;
    margin-top: slamp(15px, 30px);
    padding-left: slamp(10px, 30px);
    padding-right: slamp(10px, 30px);
    li{
      margin-left: 15px;
    }
  }
  figure{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: slamp(50px, 90px);
    margin-bottom: slamp(50px, 90px);
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  figcaption {
    font-size: slamp(14px, 16px);
    text-align: center;
    color: $black-1;
    margin-top: $base-gap;
    padding: 0 $base-gap * 2;
    line-height: 1.2;
    max-width: 480px;
  }

  p{
    margin-top: slamp(15px, 30px);
  }

  blockquote{
    border-left: 1px solid;
    padding: 5px 10px;
    font-style: italic;
    margin: 15px 0;
  }

  video{
    margin-top: slamp(50px, 90px);
    margin-bottom: slamp(50px, 90px);
  }
}

.news-detail {

  .composable__title{
    max-width: 860px;
  }

  .composable__text{
    max-width: 860px;
    padding-left: slamp(10px, 30px);
    padding-right: slamp(10px, 30px);
  }

}

.composable__gallery{
  .gallery-click{
    padding-top: slamp(50px, 90px);
    padding-bottom: slamp(50px, 90px);
  }
  & + * {
    margin-top: 0;
  }
}

.composable__simple-image{
  max-width: 1600px;
  width: 90%;

  &.--small{
    width: 80%;
    max-width: 860px;
  }

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: slamp(50px, 90px);
  margin-bottom: slamp(50px, 90px);
  margin-left: auto;
  margin-right: auto;
  

  figcaption {
    font-size: slamp(14px, 16px);
    text-align: center;
    color: $black-1;
    margin-top: $base-gap;
    padding: 0 $base-gap * 2;
    line-height: 1.2;
    max-width: 600px;
  }
}

main[data-barba-namespace=locanda] .composable__simple-image{
  &.--small{
    width: 65%;
    max-width: 650px;
  }
}
.main-nav-large{
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  transition: transform $speed-fast ease;
  background-color: $white-1;


  .main-nav-large__logo__extended{
    display: block;
  }
  .main-nav-large__logo__small{
    display: none;
  }
  .main-nav-large__wrap{
    padding: $base-gap * 2 0;
  }

  &.small{
    transform: translateY(-100%);
    box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
  }
  
  &.scrolled{
    .main-nav-large__logo__extended{
      display: none;
    }
    .main-nav-large__logo__small{
      display: block;
    }
    .main-nav-large__wrap{
      padding: $base-gap 0;
    }
  }

  &.scrolled--down{
    transform: translateY(0%);
  }
}

.main-nav-large__wrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-nav-large__links{
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-around;
  max-width: 450px;
  li + li{
    margin-left: $base-gap;
  }
  @include react('xxlarge'){
    max-width: 600px;
  }
}

.main-nav-large__logo{
  width: 130px;
  margin: 0 $base-gap * 3;
  text-align: center;
  display: flex;
  justify-content: center;
  @include react('xlarge'){
    width: 150px;
  }
}

.news-slider{
  background-color: $grey-1;

  .flickity-page-dot{
    background-color: rgba(0,0,0,0.35);
  }
  .flickity-page-dot.is-selected{
    background-color: $black-1;
  }
  .flickity-page-dot:focus{
    box-shadow: none;
  }
  .flickity-page-dots{
    position: relative;
    margin-top: slamp(30px, 60px);
    @include react('<medium'){
      display: none;
    }
  }
}

.news-slider__header{
  text-align: center;
  h2{
    color: $black-1;
  }
}
.news-slider__slide__title{
  font-size: slamp(14px, 16px);
  font-family: $font-family-2;
  text-transform: uppercase;
}


.news-slider-flickity{
  .carousel-cell{
    width: 75%;
    padding: $base-gap;
    @include react(medium){
      width: 600px;
      margin-left: $base-gap * 4;
      padding: 0;
    }
    @include react('xxlarge'){
      width: 650px;
    }
  }
}

.news-slider__slide{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.news-slider__slide__image{
  width: 100%;
  @include react(medium){
    width: 250px;
    margin-left: $base-gap * 4;
  }
  @include react('xxlarge'){
    width: 300px;
  }
  a{
    width: 100%;
    height: 100%;
    display: block;
  }
}

.news-slider__slide__header{
  width: 100%;
  flex: 1;
  a{
    color: $black-1;
    padding: $base-gap;
    display: inline-block;
  }
  @include react(medium){
    .news-slider__slide__image + &{
      margin-left: $base-gap * 2;
    }
    a{
      padding: 0;
    }
  }

  
}
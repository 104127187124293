.main-footer{
  padding-bottom: $base-gap * 2;
}

.main-footer__social, .main-footer__lang{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  li{
    width: auto;
    text-align: center;
  }
  li + li{
    margin-top: 0;
    margin-left: $base-gap * 2;
  }
}
.main-footer__lang{
  font-size: 14px;
}

.main-footer__info{
  max-width: 900px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  li{
    width: 100%;
    text-align: center;
    @include react('large'){
      width: auto;
    }
  }
  li + li{
    margin-top: $base-gap * 3;
    @include react('large'){
      margin-top: 0;
      padding-left: $base-gap * 2;
    }
  }
  h3{
    font-size: 16px;
    margin-bottom: 10px;
  }
  p{
    line-height: 1.8;
  }
  a{
    @include underline-link();
    display: inline-block;
  }
}

.main-footer__financing{
  display: flex;
  align-items: center;
  justify-content: center;
 
  img{
    width: 80%;
    max-width: 500px;
    margin: auto;
  }
}

.main-footer__company{
  text-align: center;
  p{
    padding: 0 $base-gap;
    font-size: 12px;
  }
  a{
    @include react('pointer'){
      &:hover {
        color: $red-1;
      }
    }
  }
}
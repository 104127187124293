.book-banner{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

}

.book-banner__text{
  br{
    display: none;
  }
  @include react(medium){
    br{
      display: block;
    }
  }

}
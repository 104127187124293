/* Body copy */
strong,
b {
  font-weight: $font-weight-bold;
}

.line{
  overflow: hidden;
}

/* Alignements */

.txt-center{
  text-align: center;
}
.txt-left{
  text-align: left;
}
.txt-right{
  text-align: right;
}

/* Size */

.text-1{
  font-size: slamp(16px, 20px);
}

.text-2{
  font-size: slamp(20px, 34px);
}

.text-3{
  font-size: 14px;
  text-transform: uppercase;  
  font-family: $font-family-2;
  font-weight: $font-weight-base;
}

.text-4{
  font-size: slamp(22px, 28px);
}

.text-5{
  font-size: slamp(16px, 22px);
}

.text-6{
  font-size: slamp(16px, 24px);
}

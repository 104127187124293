@import 'modern-css-reset';
@import './vars/_index.scss';
@import './mixins/_index.scss';
@import './layout/_index.scss';
@import './typo/_index.scss';

@import './components/navigation-large.scss';
@import './components/cursor.scss';
@import './components/navigation-small.scss';
@import './components/navigation-small-overlay.scss';
@import './components/footer.scss';
@import './components/page.scss';
@import './components/chapters.scss';
@import './components/book-banner.scss';
@import './components/book.scss';
@import './components/news-slider.scss';
@import './components/news-list.scss';
@import './components/news-detail.scss';
@import './components/menu-links.scss';
@import './components/menu-dishes.scss';
@import './components/rooms-features.scss';
@import './components/gallery-click.scss';
@import './components/intro.scss';
@import './components/composable.scss';
@import './components/policy.scss';
@import './components/experiences.scss';
@import './components/experience.scss';

.menu-dishes__header{
  text-align: center;
  p{
    margin-top: 5px;
  }
}

.menu-dishes__list, .menu-tasting__list{
  margin-top: slamp(25px, 40px);
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  li{
    text-align: center;
    margin-top: slamp(15px, 30px);
  }
}

.menu-dishes__list__title, .menu-dishes__list__price,
.menu-tasting__list__title, .menu-tasting__list__price{
  font-size: slamp(16px, 20px);
  font-family: $font-family-1;
}

.menu-dishes__list__price, .menu-tasting__list__price{
  margin-top: 5px;
  font-weight: $font-weight-bold;
}

/*
.menu-tasting__list{
  margin-top: slamp(25px, 40px);

  li{
    text-align: center;
    margin-top: 5px;
  }
}

.menu-tasting__list__title, .menu-tasting__list__price{
  font-size: slamp(14px, 16px);
  font-family: $font-family-2;
  display: inline-block;
}

.menu-tasting__list__price{
  margin-left: 10px;
}
*/
/* Heading */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-2;
  font-weight: $font-weight-base;
  margin: 0;
  font-size: slamp(16px, 18px);
  line-height: $line-height-title;
}

.title-1{
  font-size: slamp(25px, 50px);
  text-transform: uppercase;
}

.title-2{
  font-size: slamp(16px, 20px);
  text-transform: uppercase;
}

.title-3{
  font-size: slamp(18px, 26px);
  text-transform: uppercase;
}
body.nav-overlay-open {
  @include react('large'){
    overflow: visible!important;
  }
}

.main-nav-small{
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  transition: transform $speed-fast ease;
  background-color: $white-1;


  .main-nav-small__logo__extended{
    display: block;
  }
  .main-nav-small__logo__small{
    display: none;
  }
  .main-nav-small__wrap{
    padding: $base-gap * 2 0;
  }

  &.small{
    transform: translateY(-100%);
    box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
  }
  
  &.scrolled{
    .main-nav-small__logo__extended{
      display: none;
    }
    .main-nav-small__logo__small{
      display: block;
    }
    .main-nav-small__wrap{
      padding: $base-gap 0;
    }
  }

  &.scrolled--down{
    transform: translateY(0%);
  }
}

.main-nav-small__wrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-nav-small__content{
  display: flex;
  align-items: center;
  min-width: 100px;
  max-width: 500px;
  &.--left{
    justify-content: flex-start;
  }
  &.--right{
    justify-content: flex-end;
  }
}

.main-nav-small__logo{
  width: 130px;
  margin: 0 $base-gap;
  text-align: center;
  display: flex;
  justify-content: center;
  @include react('medium'){
    margin: 0 $base-gap * 3;
  }
}


.nav__burger{
  border: 0;
  width: 100%;
  height: 20px;
  background: transparent;
  display: flex;
  flex-direction: column;
  padding: 0;
	justify-content: space-around;
}

.nav__burger__bar{
  height: 1px;
	width: 35px;
	background-color: $black-1;
	position: relative;
	transition: all .3s ease;
	transform-origin: top center;
	transform-style: preserve-3d;
}

/* Images */
img {
  max-width: 100%; 
  height: auto;
  content-visibility: auto;
  display: block;
}


.static-image-decor{

  img {
    margin: auto;
  }
  padding-top: slamp(30px, 45px);
  padding-bottom: slamp(30px, 45px);

  @include react('mlarge'){
    padding-top: 0;
    padding-bottom: 0;
    position: absolute; 
    top: 50%; 
    &.--right{
      right: 0;
    }
  }
}
/* @group Reset */

a {
  transition: color $speed-link ease;
  text-decoration: none;
  color: inherit;
  outline: 0;

  &:focus-visible {
    box-shadow: 0 0 4px rgb(0 0 0 / 75%);
  }
}

/* @end  */

.underline-link-1{
  @include underline-link();
  display: inline-block;
}

.underline-link-2{
  @include underline-link($reverse: true);
  display: inline-block;
}

.navigation-link{
  font-size: 13px;
  text-transform: uppercase;
  font-family: $font-family-2;
  padding: 5px;
  &.active{
    color: $red-1;
  }
  @include react('pointer'){
    &:hover {
     color: $red-1;
    }
  }
}

.navigation-link-overlay{
  font-size: slamp(26px, 40px);
  text-transform: uppercase;
  font-family: $font-family-2;
  &.active {
    color: $red-1;
   }
  @include react('pointer'){
    &:hover {
     color: $red-1;
    }
  }
}

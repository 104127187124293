/* paddings */

.pad-top-1{
  padding-top: slamp(90px, 180px);
}
.pad-bottom-1{
  padding-bottom: slamp(90px, 180px);
}

.pad-top-2{
  padding-top: slamp(60px, 120px);
}
.pad-bottom-2{
  padding-bottom: slamp(60px, 120px);
}

.pad-top-3{
  padding-top: slamp(50px, 75px);
}
.pad-bottom-3{
  padding-bottom: slamp(50px, 75px);
}

.pad-top-4{
  padding-top: slamp(30px, 50px);
}
.pad-bottom-4{
  padding-bottom: slamp(30px, 50px);
}

.pad-top-5{
  padding-top: slamp(15px, 30px);
}
.pad-bottom-5{
  padding-bottom: slamp(15px, 30px);
}


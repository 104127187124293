@mixin font-face($font-name, $path, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src: url($path + '.woff2') format('woff2'),
      url($path + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin: 0;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  text-rendering: optimizespeed;
  min-height: 100vh;
  line-height: 1.5;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

html {
  min-height: auto;
}

body {
  background-color: #fff;
  min-height: auto;
}

body * {
  position: relative;
}

.pad-top-1 {
  padding-top: clamp(5.625rem, 3.21429rem + 8.03571vw, 11.25rem);
}

.pad-bottom-1 {
  padding-bottom: clamp(5.625rem, 3.21429rem + 8.03571vw, 11.25rem);
}

.pad-top-2 {
  padding-top: clamp(3.75rem, 2.14286rem + 5.35714vw, 7.5rem);
}

.pad-bottom-2 {
  padding-bottom: clamp(3.75rem, 2.14286rem + 5.35714vw, 7.5rem);
}

.pad-top-3 {
  padding-top: clamp(3.125rem, 2.45536rem + 2.23214vw, 4.6875rem);
}

.pad-bottom-3 {
  padding-bottom: clamp(3.125rem, 2.45536rem + 2.23214vw, 4.6875rem);
}

.pad-top-4 {
  padding-top: clamp(1.875rem, 1.33929rem + 1.78571vw, 3.125rem);
}

.pad-bottom-4 {
  padding-bottom: clamp(1.875rem, 1.33929rem + 1.78571vw, 3.125rem);
}

.pad-top-5 {
  padding-top: clamp(.9375rem, .535714rem + 1.33929vw, 1.875rem);
}

.pad-bottom-5 {
  padding-bottom: clamp(.9375rem, .535714rem + 1.33929vw, 1.875rem);
}

.marg-top-1 {
  margin-top: clamp(6.25rem, 4.10714rem + 7.14286vw, 11.25rem);
}

.marg-bottom-1 {
  margin-bottom: clamp(6.25rem, 4.10714rem + 7.14286vw, 11.25rem);
}

.marg-top-2 {
  margin-top: clamp(3.75rem, 2.14286rem + 5.35714vw, 7.5rem);
}

.marg-bottom-2 {
  margin-bottom: clamp(3.75rem, 2.14286rem + 5.35714vw, 7.5rem);
}

.marg-top-3 {
  margin-top: clamp(3.125rem, 2.45536rem + 2.23214vw, 4.6875rem);
}

.marg-bottom-3 {
  margin-bottom: clamp(3.125rem, 2.45536rem + 2.23214vw, 4.6875rem);
}

.marg-top-4 {
  margin-top: clamp(1.875rem, 1.33929rem + 1.78571vw, 3.125rem);
}

.marg-bottom-4 {
  margin-bottom: clamp(1.875rem, 1.33929rem + 1.78571vw, 3.125rem);
}

.marg-top-5 {
  margin-top: clamp(.9375rem, .535714rem + 1.33929vw, 1.875rem);
}

.marg-bottom-5 {
  margin-bottom: clamp(.9375rem, .535714rem + 1.33929vw, 1.875rem);
}

.marg-top-6 {
  margin-top: 5px;
}

.marg-bottom-6 {
  margin-bottom: 5px;
}

img {
  content-visibility: auto;
  max-width: 100%;
  height: auto;
  display: block;
}

.static-image-decor {
  padding-top: clamp(1.875rem, 1.47321rem + 1.33929vw, 2.8125rem);
  padding-bottom: clamp(1.875rem, 1.47321rem + 1.33929vw, 2.8125rem);
}

.static-image-decor img {
  margin: auto;
}

@media (width >= 1200px) {
  .static-image-decor {
    padding-top: 0;
    padding-bottom: 0;
    position: absolute;
    top: 50%;
  }

  .static-image-decor.--right {
    right: 0;
  }
}

main.wrapper {
  padding-top: 145px;
}

@media (width >= 1300px) {
  main.wrapper {
    padding-top: 160px;
  }
}

.row-1 {
  width: 90%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

.row-2 {
  width: 60%;
  max-width: 1260px;
  margin-left: auto;
  margin-right: auto;
}

.row-3 {
  width: 90%;
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
}

.detail-share {
  justify-content: center;
  align-items: center;
  display: flex;
}

.detail-share a + a {
  margin-left: 30px;
}

.sib-container {
  max-width: 650px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (width <= 999px) {
  .show-for-large {
    display: none;
  }
}

@media (width >= 750px) {
  .show-for-small {
    display: none;
  }
}

@media (width >= 1000px) {
  .hide-for-large {
    display: none;
  }
}

@media (width <= 749px) {
  .hide-for-small {
    display: none;
  }
}

@font-face {
  font-family: FuturaBT-Light;
  src: url("FuturaBT-Light.f0787ad9.woff2") format("woff2"), url("FuturaBT-Light.86292e78.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Augustus;
  src: url("Augustus.73658682.woff2") format("woff2"), url("Augustus.69f0521c.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

:root {
  font-size: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  letter-spacing: 0;
  color: #262626;
  font-family: FuturaBT-Light, sans-serif;
  font-size: clamp(1rem, .946429rem + .178571vw, 1.125rem);
  font-weight: 300;
  line-height: 1.7;
}

strong, b {
  font-weight: 700;
}

.line {
  overflow: hidden;
}

.txt-center {
  text-align: center;
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}

.text-1 {
  font-size: clamp(1rem, .892857rem + .357143vw, 1.25rem);
}

.text-2 {
  font-size: clamp(1.25rem, .875rem + 1.25vw, 2.125rem);
}

.text-3 {
  text-transform: uppercase;
  font-family: Augustus, serif;
  font-size: 14px;
  font-weight: 300;
}

.text-4 {
  font-size: clamp(1.375rem, 1.21429rem + .535714vw, 1.75rem);
}

.text-5 {
  font-size: clamp(1rem, .839286rem + .535714vw, 1.375rem);
}

.text-6 {
  font-size: clamp(1rem, .785714rem + .714286vw, 1.5rem);
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family: Augustus, serif;
  font-size: clamp(1rem, .946429rem + .178571vw, 1.125rem);
  font-weight: 300;
  line-height: 1.5;
}

.title-1 {
  text-transform: uppercase;
  font-size: clamp(1.5625rem, .892857rem + 2.23214vw, 3.125rem);
}

.title-2 {
  text-transform: uppercase;
  font-size: clamp(1rem, .892857rem + .357143vw, 1.25rem);
}

.title-3 {
  text-transform: uppercase;
  font-size: clamp(1.125rem, .910714rem + .714286vw, 1.625rem);
}

a {
  color: inherit;
  outline: 0;
  text-decoration: none;
  transition: color .4s;
}

a:focus-visible {
  box-shadow: 0 0 4px #000000bf;
}

.underline-link-1 {
  text-decoration: none;
  display: inline-block;
}

.underline-link-1:after {
  content: "";
  border-bottom: 1px solid;
  border-color: inherit;
  transform-origin: 100% 100%;
  width: 100%;
  transition: transform .6s;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(1);
}

@media (pointer: fine) and (hover: hover) {
  .underline-link-1:hover:after {
    transform-origin: 0 100%;
    transform: scaleX(0);
  }
}

.underline-link-1.active:after {
  transform: scaleX(0);
}

.underline-link-2 {
  text-decoration: none;
  display: inline-block;
}

.underline-link-2:after {
  content: "";
  border-bottom: 1px solid;
  border-color: inherit;
  transform-origin: 100% 100%;
  width: 100%;
  transition: transform .6s;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

@media (pointer: fine) and (hover: hover) {
  .underline-link-2:hover:after {
    transform-origin: 0 100%;
    transform: scaleX(1);
  }
}

.underline-link-2.active:after {
  transform: scaleX(1);
}

.navigation-link {
  text-transform: uppercase;
  padding: 5px;
  font-family: Augustus, serif;
  font-size: 13px;
}

.navigation-link.active {
  color: #ab040b;
}

@media (pointer: fine) and (hover: hover) {
  .navigation-link:hover {
    color: #ab040b;
  }
}

.navigation-link-overlay {
  text-transform: uppercase;
  font-family: Augustus, serif;
  font-size: clamp(1.625rem, 1.25rem + 1.25vw, 2.5rem);
}

.navigation-link-overlay.active {
  color: #ab040b;
}

@media (pointer: fine) and (hover: hover) {
  .navigation-link-overlay:hover {
    color: #ab040b;
  }
}

button {
  color: inherit;
  appearance: none;
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  transition: color .4s;
}

button:focus-visible {
  box-shadow: 0 0 4px #000000bf;
}

button:disabled {
  cursor: not-allowed;
}

.button-rectangle-1 {
  cursor: pointer;
  appearance: none;
  background-color: #fff;
  border: 1px solid #262626;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  text-decoration: none;
  transition: background-color .6s;
  display: inline-flex;
}

.button-rectangle-1 span {
  color: #262626;
  transition: color .6s;
}

@media (pointer: fine) and (hover: hover) {
  .button-rectangle-1:hover {
    background-color: #262626;
  }

  .button-rectangle-1:hover span {
    color: #fff;
  }
}

.button-rectangle-1.active {
  background-color: #262626;
}

.button-rectangle-1.active span {
  color: #fff;
}

.button-rectangle-1 span {
  text-transform: uppercase;
  font-family: Augustus, serif;
  line-height: 1;
}

.button-rectangle-2 {
  cursor: pointer;
  appearance: none;
  background-color: #f6f6f4;
  border: 1px solid #262626;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  text-decoration: none;
  transition: background-color .6s;
  display: inline-flex;
}

.button-rectangle-2 span {
  color: #262626;
  transition: color .6s;
}

@media (pointer: fine) and (hover: hover) {
  .button-rectangle-2:hover {
    background-color: #262626;
  }

  .button-rectangle-2:hover span {
    color: #f6f6f4;
  }
}

.button-rectangle-2.active {
  background-color: #262626;
}

.button-rectangle-2.active span {
  color: #f6f6f4;
}

.button-rectangle-2 span {
  text-transform: uppercase;
  font-family: Augustus, serif;
  line-height: 1;
}

input {
  border: 1px solid #262626;
  outline: 0;
}

input:focus-visible, input:disabled {
  box-shadow: 0 0 4px #000000bf;
}

select, textarea {
  appearance: none;
  border: 1px solid #262626;
  border-radius: 0;
  outline: 0;
}

select:focus-visible, textarea:focus-visible {
  box-shadow: 0 0 4px #000000bf;
}

select:disabled, textarea:disabled {
  cursor: not-allowed;
}

::placeholder {
  color: #262626;
}

.sib-form, #sib-container {
  margin: 0 !important;
  padding: 0 !important;
}

.sib-form :not(button) {
  letter-spacing: 0 !important;
  font-family: FuturaBT-Light, sans-serif !important;
  font-weight: 300 !important;
  line-height: 1.7 !important;
}

#sib-container {
  max-width: 650px !important;
}

.sib-text-form-block {
  font-size: clamp(1rem, .892857rem + .357143vw, 1.25rem) !important;
}

.sib-form .entry__field {
  border-color: #262626 !important;
  border-radius: 0 !important;
}

.email-newsletter {
  padding: 15px !important;
}

.sib-form-block .button-rectangle-1 span {
  font-family: Augustus, serif !important;
  font-weight: 300 !important;
}

.main-nav-large {
  z-index: 100;
  background-color: #fff;
  width: 100%;
  transition: transform .4s;
  position: fixed;
  top: 0;
  left: 0;
}

.main-nav-large .main-nav-large__logo__extended {
  display: block;
}

.main-nav-large .main-nav-large__logo__small {
  display: none;
}

.main-nav-large .main-nav-large__wrap {
  padding: 30px 0;
}

.main-nav-large.small {
  transform: translateY(-100%);
  box-shadow: 0 1px 5px #0000001a;
}

.main-nav-large.scrolled .main-nav-large__logo__extended {
  display: none;
}

.main-nav-large.scrolled .main-nav-large__logo__small {
  display: block;
}

.main-nav-large.scrolled .main-nav-large__wrap {
  padding: 15px 0;
}

.main-nav-large.scrolled--down {
  transform: translateY(0%);
}

.main-nav-large__wrap {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.main-nav-large__links {
  flex: 1;
  justify-content: space-around;
  align-items: center;
  max-width: 450px;
  display: flex;
}

.main-nav-large__links li + li {
  margin-left: 15px;
}

@media (width >= 1600px) {
  .main-nav-large__links {
    max-width: 600px;
  }
}

.main-nav-large__logo {
  text-align: center;
  justify-content: center;
  width: 130px;
  margin: 0 45px;
  display: flex;
}

@media (width >= 1300px) {
  .main-nav-large__logo {
    width: 150px;
  }
}

.custom-cursor {
  pointer-events: none;
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
}

@media (pointer: coarse) and (hover: none) {
  .custom-cursor {
    display: none;
  }
}

.custom-cursor__core {
  transform-origin: center;
  border-radius: 50%;
  min-width: 40px;
  min-height: 40px;
  transition: all .6s;
  transform: scale(0)translateZ(0);
}

body:not([data-cursor="default"]) .custom-cursor__core {
  opacity: 1;
  background-color: #ab040b;
}

.custom-cursor__label {
  z-index: 1;
  opacity: 0;
  transition: all .6s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.custom-cursor__label p {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  letter-spacing: .025rem;
  max-width: 100px;
  font-size: 12px;
  display: none;
}

body[data-cursor="gallery"] .custom-cursor__core {
  transform: scale(3)translateZ(0);
}

body[data-cursor="gallery"] .custom-cursor__label {
  opacity: 1;
}

body[data-cursor="gallery"] .gallery-label {
  display: block;
}

body[data-cursor="link"] .custom-cursor__core {
  transform: scale(3)translateZ(0);
}

body[data-cursor="link"] .custom-cursor__label {
  opacity: 1;
}

body[data-cursor="link"] .link-label {
  display: block;
}

@media (width >= 1000px) {
  body.nav-overlay-open {
    overflow: visible !important;
  }
}

.main-nav-small {
  z-index: 100;
  background-color: #fff;
  width: 100%;
  transition: transform .4s;
  position: fixed;
  top: 0;
  left: 0;
}

.main-nav-small .main-nav-small__logo__extended {
  display: block;
}

.main-nav-small .main-nav-small__logo__small {
  display: none;
}

.main-nav-small .main-nav-small__wrap {
  padding: 30px 0;
}

.main-nav-small.small {
  transform: translateY(-100%);
  box-shadow: 0 1px 5px #0000001a;
}

.main-nav-small.scrolled .main-nav-small__logo__extended {
  display: none;
}

.main-nav-small.scrolled .main-nav-small__logo__small {
  display: block;
}

.main-nav-small.scrolled .main-nav-small__wrap {
  padding: 15px 0;
}

.main-nav-small.scrolled--down {
  transform: translateY(0%);
}

.main-nav-small__wrap {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.main-nav-small__content {
  align-items: center;
  min-width: 100px;
  max-width: 500px;
  display: flex;
}

.main-nav-small__content.--left {
  justify-content: flex-start;
}

.main-nav-small__content.--right {
  justify-content: flex-end;
}

.main-nav-small__logo {
  text-align: center;
  justify-content: center;
  width: 130px;
  margin: 0 15px;
  display: flex;
}

@media (width >= 750px) {
  .main-nav-small__logo {
    margin: 0 45px;
  }
}

.nav__burger {
  background: none;
  border: 0;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 20px;
  padding: 0;
  display: flex;
}

.nav__burger__bar {
  transform-origin: top;
  transform-style: preserve-3d;
  background-color: #262626;
  width: 35px;
  height: 1px;
  transition: all .3s;
  position: relative;
}

.main-nav-overlay {
  z-index: 100;
  width: 100%;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  height: -webkit-fill-available;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.main-nav-overlay__wrap {
  flex-direction: column;
  height: 100%;
  min-height: 500px;
  padding: 30px 0 45px;
  display: flex;
}

.main-nav-overlay__links {
  width: 100%;
  padding: 45px 0;
}

.main-nav-overlay__links li {
  text-align: center;
  width: 100%;
  overflow: hidden;
}

.main-nav-overlay__links li + li {
  margin-top: 7.5px;
}

.main-nav-overlay__close {
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
}

.main-nav-overlay__switcher {
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.main-nav-overlay__switcher li + li {
  margin-left: 22.5px;
}

.main-nav-overlay__switcher a {
  font-size: 14px;
}

.main-footer {
  padding-bottom: 30px;
}

.main-footer__social, .main-footer__lang {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.main-footer__social li, .main-footer__lang li {
  text-align: center;
  width: auto;
}

.main-footer__social li + li, .main-footer__lang li + li {
  margin-top: 0;
  margin-left: 30px;
}

.main-footer__lang {
  font-size: 14px;
}

.main-footer__info {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 900px;
  margin: auto;
  display: flex;
}

.main-footer__info li {
  text-align: center;
  width: 100%;
}

@media (width >= 1000px) {
  .main-footer__info li {
    width: auto;
  }
}

.main-footer__info li + li {
  margin-top: 45px;
}

@media (width >= 1000px) {
  .main-footer__info li + li {
    margin-top: 0;
    padding-left: 30px;
  }
}

.main-footer__info h3 {
  margin-bottom: 10px;
  font-size: 16px;
}

.main-footer__info p {
  line-height: 1.8;
}

.main-footer__info a {
  text-decoration: none;
  display: inline-block;
}

.main-footer__info a:after {
  content: "";
  border-bottom: 1px solid;
  border-color: inherit;
  transform-origin: 100% 100%;
  width: 100%;
  transition: transform .6s;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(1);
}

@media (pointer: fine) and (hover: hover) {
  .main-footer__info a:hover:after {
    transform-origin: 0 100%;
    transform: scaleX(0);
  }
}

.main-footer__info a.active:after {
  transform: scaleX(0);
}

.main-footer__financing {
  justify-content: center;
  align-items: center;
  display: flex;
}

.main-footer__financing img {
  width: 80%;
  max-width: 500px;
  margin: auto;
}

.main-footer__company {
  text-align: center;
}

.main-footer__company p {
  padding: 0 15px;
  font-size: 12px;
}

@media (pointer: fine) and (hover: hover) {
  .main-footer__company a:hover {
    color: #ab040b;
  }
}

.page-header {
  padding-top: clamp(1.875rem, 6.25vw, 6.25rem);
}

.page-header__title {
  text-align: center;
  width: 100%;
}

@media (width >= 1300px) {
  .page-header__title > * {
    max-width: 950px;
  }
}

.page-header__title > * {
  max-width: 800px;
  margin: auto;
}

@media (width >= 1300px) {
  .page-header__title > * {
    max-width: 950px;
  }
}

.page-header__image {
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.page-header__text {
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
}

.page-separator__import {
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 700px;
  margin: auto;
  display: flex;
}

.page-separator__import:before, .page-separator__import:after {
  content: "";
  background-color: #b8b8b6;
  width: calc(50% - 45px);
  height: 1px;
  display: block;
}

.transition-1 {
  opacity: 0;
}

.chapters {
  padding-top: clamp(1.875rem, 6.25vw, 6.25rem);
}

.chapter {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (width >= 1000px) {
  .chapter.chapter--odd {
    flex-direction: row-reverse;
  }

  .chapter.chapter--odd .chapter__text {
    margin-left: 5%;
  }
}

.chapter__header {
  text-align: center;
  width: 100%;
}

.chapter__header br {
  display: none;
}

@media (width >= 1300px) {
  .chapter__header br {
    display: block;
  }
}

.chapter__title {
  max-width: 800px;
  margin: auto;
}

@media (width >= 1300px) {
  .chapter__title {
    max-width: 950px;
  }
}

@media (width >= 1000px) {
  .chapter__image {
    width: 45%;
  }
}

.chapter__image a {
  width: 100%;
  height: 100%;
  display: block;
}

.chapter__text {
  margin-top: clamp(1.875rem, 1.07143rem + 2.67857vw, 3.75rem);
}

@media (width >= 1000px) {
  .chapter__text {
    width: 40%;
  }
}

.chapter__text .chapter__text__content {
  padding: 15px;
}

@media (width >= 1000px) {
  .chapter__text .chapter__text__content {
    max-width: 340px;
    padding: 0;
  }
}

@media (width >= 1300px) {
  .chapter__text .chapter__text__content {
    max-width: 450px;
    padding: 0;
  }
}

.chapter__text .chapter__text__content a {
  color: #ab040b;
}

.chapter__text .chapter__text__content p {
  font-size: clamp(1rem, .785714rem + .714286vw, 1.5rem);
}

.book-banner {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.book-banner__text br {
  display: none;
}

@media (width >= 750px) {
  .book-banner__text br {
    display: block;
  }
}

.book-info__content {
  margin-top: clamp(1.25rem, .982143rem + .892857vw, 1.875rem);
}

.book-info__content a {
  text-decoration: none;
  display: inline-block;
}

.book-info__content a:after {
  content: "";
  border-bottom: 1px solid;
  border-color: inherit;
  transform-origin: 100% 100%;
  width: 100%;
  transition: transform .6s;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(1);
}

@media (pointer: fine) and (hover: hover) {
  .book-info__content a:hover:after {
    transform-origin: 0 100%;
    transform: scaleX(0);
  }
}

.book-info__content a.active:after {
  transform: scaleX(0);
}

.news-slider {
  background-color: #f6f6f4;
}

.news-slider .flickity-page-dot {
  background-color: #00000059;
}

.news-slider .flickity-page-dot.is-selected {
  background-color: #262626;
}

.news-slider .flickity-page-dot:focus {
  box-shadow: none;
}

.news-slider .flickity-page-dots {
  margin-top: clamp(1.875rem, 1.07143rem + 2.67857vw, 3.75rem);
  position: relative;
}

@media (width <= 749px) {
  .news-slider .flickity-page-dots {
    display: none;
  }
}

.news-slider__header {
  text-align: center;
}

.news-slider__header h2 {
  color: #262626;
}

.news-slider__slide__title {
  text-transform: uppercase;
  font-family: Augustus, serif;
  font-size: clamp(.875rem, .821429rem + .178571vw, 1rem);
}

.news-slider-flickity .carousel-cell {
  width: 75%;
  padding: 15px;
}

@media (width >= 750px) {
  .news-slider-flickity .carousel-cell {
    width: 600px;
    margin-left: 60px;
    padding: 0;
  }
}

@media (width >= 1600px) {
  .news-slider-flickity .carousel-cell {
    width: 650px;
  }
}

.news-slider__slide {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.news-slider__slide__image {
  width: 100%;
}

@media (width >= 750px) {
  .news-slider__slide__image {
    width: 250px;
    margin-left: 60px;
  }
}

@media (width >= 1600px) {
  .news-slider__slide__image {
    width: 300px;
  }
}

.news-slider__slide__image a {
  width: 100%;
  height: 100%;
  display: block;
}

.news-slider__slide__header {
  flex: 1;
  width: 100%;
}

.news-slider__slide__header a {
  color: #262626;
  padding: 15px;
  display: inline-block;
}

@media (width >= 750px) {
  .news-slider__slide__image + .news-slider__slide__header {
    margin-left: 30px;
  }

  .news-slider__slide__header a {
    padding: 0;
  }
}

.news-list {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.news-list__news {
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  max-width: 800px;
  display: flex;
}

@media (width >= 1300px) {
  .news-list__news {
    max-width: 950px;
  }
}

.news-list__news + .news-list__news {
  margin-top: clamp(3.75rem, 2.67857rem + 3.57143vw, 6.25rem);
}

.news-list__news__image {
  width: 100%;
}

@media (width >= 750px) {
  .news-list__news__image {
    width: clamp(15.625rem, 11.0714rem + 15.1786vw, 26.25rem);
  }
}

.news-list__news__image a {
  width: 100%;
  height: 100%;
  display: block;
}

.news-list__news__header {
  flex: 1;
  width: 100%;
  padding: 15px;
}

.news-list__news__header a {
  color: #ab040b;
}

@media (width >= 750px) {
  .news-list__news__header {
    max-width: 500px;
    padding: 0;
  }

  .news-list__news__image + .news-list__news__header {
    margin-left: clamp(1.875rem, 5vw - 1.25rem, 3.75rem);
  }
}

.news-list__pagination {
  justify-content: center;
  align-items: center;
  display: flex;
}

.news-list__pagination a + a {
  margin-left: 30px;
}

.news-detail .page-header__image {
  max-width: 1260px;
}

.news-detail__related h3 {
  text-align: center;
}

.news-detail__info {
  justify-content: center;
  display: flex;
}

.news-detail__data {
  margin-left: 15px;
}

.tag {
  color: #ab040b;
  background-color: #fafafa;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 2px 5px;
  font-size: 13px;
  display: inline-block;
}

.menu-links {
  justify-content: center;
  display: flex;
}

.menu-links li + li {
  margin-left: 30px;
}

.menu-links a:not(.active) {
  color: #ab040b;
}

.menu-dishes__header {
  text-align: center;
}

.menu-dishes__header p {
  margin-top: 5px;
}

.menu-dishes__list, .menu-tasting__list {
  max-width: 600px;
  margin-top: clamp(1.5625rem, 1.16071rem + 1.33929vw, 2.5rem);
  margin-left: auto;
  margin-right: auto;
}

.menu-dishes__list li, .menu-tasting__list li {
  text-align: center;
  margin-top: clamp(.9375rem, .535714rem + 1.33929vw, 1.875rem);
}

.menu-dishes__list__title, .menu-dishes__list__price, .menu-tasting__list__title, .menu-tasting__list__price {
  font-family: FuturaBT-Light, sans-serif;
  font-size: clamp(1rem, .892857rem + .357143vw, 1.25rem);
}

.menu-dishes__list__price, .menu-tasting__list__price {
  margin-top: 5px;
  font-weight: 700;
}

.rooms-features__header {
  text-align: center;
}

.rooms-features__header p {
  max-width: 600px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
}

.rooms-features__list {
  width: 100%;
  max-width: 800px;
  margin-top: clamp(1.5625rem, 1.16071rem + 1.33929vw, 2.5rem);
  margin-left: auto;
  margin-right: auto;
}

.rooms-features__list li {
  align-items: center;
  margin-top: clamp(.9375rem, .535714rem + 1.33929vw, 1.875rem);
  padding-top: clamp(.9375rem, .535714rem + 1.33929vw, 1.875rem);
  display: flex;
}

.rooms-features__list li + li {
  border-top: 1px solid #b8b8b6;
}

.rooms-features__list__ico {
  width: 25px;
  height: 25px;
  margin-right: clamp(1.25rem, .714286rem + 1.78571vw, 2.5rem);
}

.rooms-features__list__title {
  font-family: FuturaBT-Light, sans-serif;
  font-size: clamp(1rem, .946429rem + .178571vw, 1.125rem);
}

.rooms-prices__list {
  margin-top: clamp(1.5625rem, 1.16071rem + 1.33929vw, 2.5rem);
}

.rooms-prices__list li {
  text-align: center;
  margin-top: clamp(.9375rem, .535714rem + 1.33929vw, 1.875rem);
}

.rooms-prices__list__note {
  font-size: clamp(.875rem, .821429rem + .178571vw, 1rem);
}

.rooms-prices__list__title, .rooms-prices__list__price {
  font-family: FuturaBT-Light, sans-serif;
  font-size: clamp(1rem, .892857rem + .357143vw, 1.25rem);
}

.rooms-prices__list__price {
  margin-top: 5px;
  font-weight: 700;
}

.menu-gallery, .rooms-gallery {
  color: #fff;
  background-color: #6f5d5d;
}

.gallery-click__wrap {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.gallery-click__slide {
  width: 100%;
  max-width: 1260px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.gallery-click__slide.--visible {
  z-index: 3;
}

.gallery-click__slide:not(.--visible) {
  cursor: pointer;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gallery-click__slide.--visible--next {
  z-index: 2;
}

.gallery-click__slide picture {
  width: 100%;
  height: 100%;
}

.gallery-click__slide picture img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.gallery-click__slide picture.vertical img {
  max-width: 50%;
  margin: auto;
}

.gallery-click__slide picture.horizontal {
  margin: auto;
  padding: 6%;
}

.rooms-gallery .gallery-click__slide picture.horizontal, .composable__gallery .gallery-click__slide picture.horizontal {
  padding: 0;
}

.composable__gallery:last-child .gallery-click {
  padding-bottom: 0;
}

.gallery-click__instructions {
  width: 100%;
}

.gallery-click__instructions p {
  text-align: center;
  font-size: clamp(.875rem, .821429rem + .178571vw, 1rem);
}

.intro {
  height: 100vh;
  z-index: 200;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: -webkit-fill-available;
  display: flex;
  position: fixed;
}

.intro__wrap {
  opacity: 0;
  justify-content: center;
  display: flex;
}

.intro__wrap .word {
  opacity: 0;
}

.composable__title, .composable__text {
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
}

.composable__text a {
  text-decoration: none;
  display: inline-block;
}

.composable__text a:after {
  content: "";
  border-bottom: 1px solid;
  border-color: inherit;
  transform-origin: 100% 100%;
  width: 100%;
  transition: transform .6s;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(1);
}

@media (pointer: fine) and (hover: hover) {
  .composable__text a:hover:after {
    transform-origin: 0 100%;
    transform: scaleX(0);
  }
}

.composable__text a.active:after {
  transform: scaleX(0);
}

.composable__text ul {
  margin-top: clamp(.9375rem, .535714rem + 1.33929vw, 1.875rem);
  margin-left: 0;
  padding-left: clamp(.625rem, .0892857rem + 1.78571vw, 1.875rem);
  padding-right: clamp(.625rem, .0892857rem + 1.78571vw, 1.875rem);
  list-style: disc;
}

.composable__text ul li {
  margin-left: 15px;
}

.composable__text figure {
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  margin: clamp(3.125rem, 2.05357rem + 3.57143vw, 5.625rem) auto;
  display: flex;
}

.composable__text figcaption {
  text-align: center;
  color: #262626;
  max-width: 480px;
  margin-top: 15px;
  padding: 0 30px;
  font-size: clamp(.875rem, .821429rem + .178571vw, 1rem);
  line-height: 1.2;
}

.composable__text p {
  margin-top: clamp(.9375rem, .535714rem + 1.33929vw, 1.875rem);
}

.composable__text blockquote {
  border-left: 1px solid;
  margin: 15px 0;
  padding: 5px 10px;
  font-style: italic;
}

.composable__text video {
  margin-top: clamp(3.125rem, 2.05357rem + 3.57143vw, 5.625rem);
  margin-bottom: clamp(3.125rem, 2.05357rem + 3.57143vw, 5.625rem);
}

.news-detail .composable__title {
  max-width: 860px;
}

.news-detail .composable__text {
  max-width: 860px;
  padding-left: clamp(.625rem, .0892857rem + 1.78571vw, 1.875rem);
  padding-right: clamp(.625rem, .0892857rem + 1.78571vw, 1.875rem);
}

.composable__gallery .gallery-click {
  padding-top: clamp(3.125rem, 2.05357rem + 3.57143vw, 5.625rem);
  padding-bottom: clamp(3.125rem, 2.05357rem + 3.57143vw, 5.625rem);
}

.composable__gallery + * {
  margin-top: 0;
}

.composable__simple-image {
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  max-width: 1600px;
  margin: clamp(3.125rem, 2.05357rem + 3.57143vw, 5.625rem) auto;
  display: flex;
}

.composable__simple-image.--small {
  width: 80%;
  max-width: 860px;
}

.composable__simple-image figcaption {
  text-align: center;
  color: #262626;
  max-width: 600px;
  margin-top: 15px;
  padding: 0 30px;
  font-size: clamp(.875rem, .821429rem + .178571vw, 1rem);
  line-height: 1.2;
}

main[data-barba-namespace="locanda"] .composable__simple-image.--small {
  width: 65%;
  max-width: 650px;
}

.policy__content {
  text-align: left;
  max-width: 900px;
  margin-top: clamp(1.5625rem, 1.16071rem + 1.33929vw, 2.5rem);
}

.policy__content ul {
  margin-top: clamp(.9375rem, .535714rem + 1.33929vw, 1.875rem);
  margin-left: 40px;
  list-style: disc;
}

.policy__content p {
  margin-top: clamp(.9375rem, .535714rem + 1.33929vw, 1.875rem);
}

.policy__content a {
  text-decoration: none;
  display: inline-block;
}

.policy__content a:after {
  content: "";
  border-bottom: 1px solid;
  border-color: inherit;
  transform-origin: 100% 100%;
  width: 100%;
  transition: transform .6s;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(1);
}

@media (pointer: fine) and (hover: hover) {
  .policy__content a:hover:after {
    transform-origin: 0 100%;
    transform: scaleX(0);
  }
}

.policy__content a.active:after {
  transform: scaleX(0);
}

.experience-header {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 1260px;
  margin-left: auto;
  margin-right: auto;
  padding-top: clamp(1.875rem, 8.92857vw - .803571rem, 8.125rem);
  display: flex;
}

.experience-header__title {
  text-align: center;
  max-width: 800px;
  margin: auto;
}

@media (width >= 1300px) {
  .experience-header__title {
    max-width: 950px;
  }
}

.experience-header__info {
  text-align: center;
  width: 100%;
}

@media (width >= 1000px) {
  .experience-header__image {
    width: 45%;
  }
}

.experience-header__image a {
  width: 100%;
  height: 100%;
  display: block;
}

.experience-header__image img {
  width: 100%;
}

.experience-header__text {
  margin-top: clamp(1.875rem, 1.07143rem + 2.67857vw, 3.75rem);
}

@media (width >= 1000px) {
  .experience-header__text {
    width: 40%;
  }
}

.experience-header__text .experience-header__text__content {
  padding: 15px;
}

@media (width >= 1000px) {
  .experience-header__text .experience-header__text__content {
    max-width: 375px;
    padding: 0;
  }
}

@media (width >= 1300px) {
  .experience-header__text .experience-header__text__content {
    max-width: 450px;
    padding: 0;
  }
}

.experience-header__text .experience-header__text__content a {
  color: #ab040b;
}

.experience-book {
  background-color: #f6f6f4;
}

@media (width >= 1000px) {
  .experience-book__wrapper {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

.experience-book__info {
  text-align: center;
  max-width: 600px;
  margin: auto;
}

@media (width >= 1000px) {
  .experience-book__info {
    text-align: left;
    width: 50%;
    max-width: none;
  }
}

.experience-book__cta {
  text-align: center;
  margin-top: clamp(1.875rem, 1.33929rem + 1.78571vw, 3.125rem);
}

@media (width >= 1000px) {
  .experience-book__cta {
    width: 45%;
    margin-top: 0;
  }
}

/*! Flickity v3.0.0
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  touch-action: pan-y;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.flickity-slider {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}

.flickity-rtl .flickity-slider {
  left: unset;
  right: 0;
}

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: grabbing;
}

.flickity-cell {
  position: absolute;
  left: 0;
}

.flickity-rtl .flickity-cell {
  left: unset;
  right: 0;
}

.flickity-button {
  color: #333;
  background: #ffffffbf;
  border: none;
  position: absolute;
}

.flickity-button:hover {
  cursor: pointer;
  background: #fff;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
  opacity: .6;
}

.flickity-button:disabled {
  opacity: .3;
  cursor: auto;
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

.flickity-prev-next-button {
  border-radius: 50%;
  width: 44px;
  height: 44px;
  top: 50%;
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  left: 10px;
  right: auto;
}

.flickity-prev-next-button .flickity-button-icon {
  width: 60%;
  height: 60%;
  position: absolute;
  top: 20%;
  left: 20%;
}

.flickity-page-dots {
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  display: flex;
  position: absolute;
  bottom: -25px;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dot {
  cursor: pointer;
  appearance: none;
  text-indent: -9999px;
  background: #33333340;
  border: none;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  padding: 0;
  display: block;
  overflow: hidden;
}

.flickity-rtl .flickity-page-dot {
  text-indent: 9999px;
}

.flickity-page-dot:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-page-dot.is-selected {
  background: #333;
}

/*# sourceMappingURL=app.css.map */


.news-detail .page-header__image{
  max-width: 1260px;
}

.news-detail__related h3{
  text-align: center;
}



.news-detail__info{
  display: flex;
  justify-content: center;
}

.news-detail__data{
  margin-left: $base-gap;
}

.tag{
  color: $red-1;
  padding: 2px 5px;
  font-size: 13px;
  background-color: #fafafa;
  border: 1px solid #eaeaea;
  display: inline-block;
  border-radius: 8px;
}
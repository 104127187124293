/* Input */

input {
  outline: 0;
  border: 1px solid $text-base;

  &:focus-visible {
    box-shadow: 0 0 4px rgb(0 0 0 / 75%);
  }

  &:disabled {
    box-shadow: 0 0 4px rgb(0 0 0 / 75%);
  }
}

select,
textarea {
  border-radius: 0;
  appearance: none;
  border: 1px solid $text-base;
  outline: 0;

  &:focus-visible {
    box-shadow: 0 0 4px rgb(0 0 0 / 75%);
  }

  &:disabled {
    cursor: not-allowed;
  }
}

::placeholder {
  color: $text-base;
}

.sib-form, #sib-container{
  padding: 0!important;
  margin: 0!important;
}

.sib-form *:not(button){
  font-family: $font-family-1!important;;
  font-weight: $font-weight-base!important;;
  letter-spacing: $font-spacing-base!important;;
  line-height: $line-height-base!important;;
}

#sib-container{
  max-width: 650px!important;
}

.sib-text-form-block{
  font-size: slamp(16px, 20px)!important;
}

.sib-form .entry__field{
  border-radius: 0!important;
  border-color: $black-1!important;
}
.email-newsletter{
  padding: $base-gap!important;

}

.sib-form-block .button-rectangle-1 span{
  font-family: $font-family-2!important;
  font-weight: $font-weight-base!important;
}

.menu-gallery, .rooms-gallery{
  background-color: $brown-1;
  color: $white-1;
}

.gallery-click__wrap{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.gallery-click__slide{
  height: 100%;
  width: 100%;
  max-width: 1260px;
  margin-left: auto;
  margin-right: auto;
  &.--visible{
    z-index: 3;
  }
  &:not(.--visible){
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  &.--visible--next{
    z-index: 2;
  }
}

.gallery-click__slide picture{
  width: 100%;
  height: 100%;
  img{
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  &.vertical img{
    max-width: 50%;
    margin: auto;
  }
  &.horizontal {
    padding: 6%;
    margin: auto;
  }
}

.rooms-gallery .gallery-click__slide picture{
  &.horizontal {
    padding: 0;
  }
}

.composable__gallery .gallery-click__slide picture{
  &.horizontal {
    padding: 0;
  }
}

.composable__gallery:last-child .gallery-click{
  padding-bottom: 0;
}

.gallery-click__instructions{
  width: 100%;
  p{
    font-size: slamp(14px, 16px);
    text-align: center;
  }
}

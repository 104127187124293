.news-list{
  display: flex;
  align-items: center;
  flex-direction: column;
}

.news-list__news{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 800px;
  @include react('xlarge'){
    max-width: 950px;
  }

  & + & {
    margin-top: slamp(60px, 100px);
  }
}

.news-list__news__image{
  width: 100%;
  @include react(medium){
    width: slamp(250px, 420px);
  }
  a{
    width: 100%;
    height: 100%;
    display: block;
  }
}

.news-list__news__header{
  width: 100%;
  flex: 1;
  padding: $base-gap;
  a{
    color: $red-1;
  }
  @include react(medium){
    max-width: 500px;
    padding: 0;
    .news-list__news__image + &{
      margin-left: slamp( $base-gap * 2,  $base-gap * 4, 1000px);
    }
  }

}


.news-list__pagination{
  display: flex;
  align-items: center;
  justify-content: center;
  a + a{
    margin-left: $base-gap * 2;
  }
}
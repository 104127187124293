$background-base: #fff;
$text-base: #262626;


$red-1: #AB040B;
$brown-1: #6f5d5d;
$black-1: #262626;
$white-1: #fff;
$grey-1: #F6F6F4;
$grey-2: #B8B8B6;


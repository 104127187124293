.intro{
  position: fixed;
  height: 100vh;
  height: -webkit-fill-available;
  width: 100vw;
  z-index: 200;
  background-color: $white-1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.intro__wrap{
  display: flex;
  justify-content: center;
  opacity: 0;
  .word{
    opacity: 0;
  }
}
/* Layout */

main.wrapper{
  padding-top: $menu-small-height;
  @include react('xlarge'){
    padding-top: $menu-large-height;
  }
}


.row-1{
  max-width: 1600px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.row-2{
  max-width: 1260px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.row-3{
  max-width: 1800px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.detail-share{
  display: flex;
  justify-content: center;
  align-items: center;
  a + a{
    margin-left: $base-gap * 2;
  }
}

.sib-container{
  max-width: 650px;
}
html {
  min-height: auto;
  // scroll-behavior: auto !important;
}

body {
  background-color: $background-base;
  min-height: auto;
  *{
    position: relative;
  }
}
/* @group Reset */

button {
  transition: color $speed-fast ease;
  padding: 0;
  margin: 0;
  border: none;
  color: inherit;
  background: none;
  appearance: none;
  cursor: pointer;

  &:focus-visible {
    box-shadow: 0 0 4px rgb(0 0 0 / 75%);
  }

  &:disabled {
    cursor: not-allowed;
  }
}

/* @end */

.button-rectangle-1{
  @include rectangle-button();
  span{
    text-transform: uppercase;
    font-family: $font-family-2;
    line-height: 1;
  }
}

.button-rectangle-2{
  @include rectangle-button($background:  $grey-1, $colorHover: $grey-1);
  span{
    text-transform: uppercase;
    font-family: $font-family-2;
    line-height: 1;
  }
}
.main-nav-overlay{
  z-index: 100;
  background-color: #fff;
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.main-nav-overlay__wrap{
  padding: $base-gap * 2 0 $base-gap * 3;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 500px;
}

.main-nav-overlay__links{
  width: 100%;
  padding: $base-gap * 3 0;
  li{
    width: 100%;
    overflow: hidden;
    text-align: center;
    & + li {
      margin-top: $base-gap * 0.5;
    }
  }
}

.main-nav-overlay__close{
  font-size: 14px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: auto;
}


.main-nav-overlay__switcher{
  display: flex;
  margin-top: auto;
  margin-right: auto;
  margin-left: auto;
  li + li{
    margin-left: $base-gap * 1.5;
  }
  a{
    font-size: 14px;
  }
}
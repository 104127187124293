/* Margins */

.marg-top-1{
  margin-top: slamp(100px, 180px);
}
.marg-bottom-1{
  margin-bottom: slamp(100px, 180px);
}

.marg-top-2{
  margin-top: slamp(60px, 120px);
}
.marg-bottom-2{
  margin-bottom: slamp(60px, 120px);
}

.marg-top-3{
  margin-top: slamp(50px, 75px);
}
.marg-bottom-3{
  margin-bottom: slamp(50px, 75px);
}

.marg-top-4{
  margin-top: slamp(30px, 50px);
}
.marg-bottom-4{
  margin-bottom: slamp(30px, 50px);
}

.marg-top-5{
  margin-top: slamp(15px, 30px);
}
.marg-bottom-5{
  margin-bottom: slamp(15px, 30px);
}

.marg-top-6{
  margin-top: 5px;
}
.marg-bottom-6{
  margin-bottom: 5px;
}


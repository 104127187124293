/* Buttons */

@mixin rectangle-button(
  $padding: 20px 40px,
  $color: #262626,
  $background: #fff, 
  $border: #262626, 
  $colorHover: #fff, 
  $backgroundHover: #262626,
  $speed: $speed-medium,
  $activeClass: 'active'
)
{
  background-color: $background;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $border;
  padding: $padding;
  transition: background-color $speed ease;;
  appearance: none;

  span{
    transition: color $speed ease;
    color: $color;
  }

  @include react('pointer'){
    &:hover {
      background-color: $backgroundHover;
      span{
        color: $colorHover;
      }
    }
  }

  &.#{$activeClass} {
    background-color: $backgroundHover;
    span{
      color: $colorHover;
    }
  }
}

.rooms-features__header{
  text-align: center;
  p{
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    margin-top: 5px;
  }
}

.rooms-features__list{
  margin-top: slamp(25px, 40px);
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  li{
    margin-top: slamp(15px, 30px);
    padding-top: slamp(15px, 30px);
    display: flex;
    align-items: center;
  }
  li + li{
    border-top: 1px solid $grey-2;
  }
}

.rooms-features__list__ico{
  width: 25px;
  height: 25px;
  margin-right: slamp(20px, 40px);
}

.rooms-features__list__title{
  font-size: slamp(16px, 18px);
  font-family: $font-family-1;
}



.rooms-prices__list{
  margin-top: slamp(25px, 40px);

  li{
    text-align: center;
    margin-top: slamp(15px, 30px);
  }
}

/* .rooms-prices__list__title, .rooms-prices__list__price{
  font-size: slamp(14px, 16px);
  font-family: $font-family-2;
  display: inline-block;
}

.rooms-prices__list__price{
  margin-left: 10px;
} */

.rooms-prices__list__note{
  font-size: slamp(14px, 16px);
}

.rooms-prices__list__title, .rooms-prices__list__price{
  font-size: slamp(16px, 20px);
  font-family: $font-family-1;
}

.rooms-prices__list__price{
  margin-top: 5px;
  font-weight: $font-weight-bold;
}

.page-header{
  padding-top: slamp(30px, 100px);
}

.page-header__title{
  width: 100%;
  text-align: center;
}

.page-header__title > *{
  max-width: 800px;
  margin: auto;

  @include react('xlarge'){
    max-width: 950px;
  }
}

.page-header__title > *{
  max-width: 800px;
  margin: auto;

  @include react('xlarge'){
    max-width: 950px;
  }
}
.page-header__image{
  // max-width: 1260px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

.page-header__text{
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  // text-align: center;

}

.page-separator__import{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: auto;
  max-width: 700px;
  width: 100%;
  &:before{
    content: '';
    display: block;
    height: 1px;
    background-color: $grey-2;
    width: calc(50% - $base-gap * 3);
  }
  &:after{
    content: '';
    display: block;
    height: 1px;
    background-color: $grey-2;
    width: calc(50% - $base-gap * 3);
  }
}

.transition-1{
  opacity: 0;
}
/* Visibility */
.show-for-large{
  @include react('<large'){
    display: none;
  }
}
.show-for-small{
  @include react('medium'){
    display: none;
  }
}
.hide-for-large{
  @include react('large'){
    display: none;
  }
}
.hide-for-small{
  @include react('<medium'){
    display: none;
  }
}


.custom-cursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: 50;

  @include react('touch') {
    display: none;
  }
}


.custom-cursor__core{
  transform: scale(0) translateZ(0);
  transform-origin: center center;
  transition: all $speed-medium;
  // transition-delay: 0.15s;
  border-radius: 50%;
  min-width: 40px;
  min-height: 40px;  
}

/* @group View */
body:not([data-cursor="default"])
 {
  .custom-cursor__core{
    background-color: $red-1;
    opacity: 1;
  }
}

.custom-cursor__label{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 1;
  opacity: 0;
  transition: all $speed-medium;
  p{
    font-size: 12px;
    text-transform: uppercase;
    color: $white-1;
    text-align: center;
    max-width: 100px;
    letter-spacing: 0.025rem;
    display: none;
  }
}


body[data-cursor="gallery"] {
  .custom-cursor__core{
    transform: scale(3) translateZ(0);
    // transition-delay: 0s;
  }
  .custom-cursor__label{
    opacity: 1;
    // transition-delay: $speed-fast;
  }
  .gallery-label{
    display: block;
  }
}

body[data-cursor="link"] {
  .custom-cursor__core{
    transform: scale(3) translateZ(0);
    // transition-delay: 0s;
  }
  .custom-cursor__label{
    opacity: 1;
    // transition-delay: $speed-fast;
  }
  .link-label{
    display: block;
  }
}
.experience-header{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: slamp(30px, 130px);
  max-width: 1260px;
  margin-left: auto;
  margin-right: auto;
}


.experience-header__title{
  max-width: 800px;
  text-align: center;
  margin: auto;
  @include react('xlarge'){
    max-width: 950px;
  }
}

.experience-header__info {
  width: 100%;
  text-align: center;
}

.experience-header__image{
  @include react('large'){
    width: 45%;
  }
  a{
    width: 100%;
    height: 100%;
    display: block;
  }
  img{
    width: 100%;
  }
}

.experience-header__text{
  margin-top: slamp(30px, 60px);
  @include react('large'){
    width: 40%;
  }
}

.experience-header__text .experience-header__text__content{
  padding: $base-gap;
  @include react('large'){
    padding: 0;
    max-width: 375px;
  }
  @include react('xlarge'){
    padding: 0;
    max-width: 450px;
  }
  a{
    color: $red-1;
  }
}

.experience-book{
  background-color: $grey-1;
}

.experience-book{
  background-color: $grey-1;
}


.experience-book__wrapper {
  @include react('large'){
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.experience-book__info {
  text-align: center;
  max-width: 600px;
  margin: auto;
  @include react('large'){
    max-width: none;
    text-align: left;
    width: 50%;
  }
}
.experience-book__cta {
  text-align: center;
  margin-top: slamp(30px, 50px);

  @include react('large'){
    margin-top: 0;
    width: 45%;
  }
}
.chapters{
  padding-top: slamp(30px, 100px);
}

.chapter{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  @include react('large'){
    &.chapter--odd{
      flex-direction: row-reverse;
      .chapter__text{
        margin-left: 5%;
      }
    }
    
  }
}

.chapter__header{
  width: 100%;
  text-align: center;
  br{
    display: none;
  }
  @include react('xlarge'){
    br{
      display: block;
    }
  }
}

.chapter__title{
  max-width: 800px;
  margin: auto;
  @include react('xlarge'){
    max-width: 950px;
  }
}

.chapter__image{
  @include react('large'){
    width: 45%;
  }
  a{
    width: 100%;
    height: 100%;
    display: block;
  }
}

.chapter__text{
  margin-top: slamp(30px, 60px);
  @include react('large'){
    width: 40%;
  }
}

.chapter__text .chapter__text__content{
  padding: $base-gap;
  @include react('large'){
    padding: 0;
    max-width: 340px;
  }
  @include react('xlarge'){
    padding: 0;
    max-width: 450px;
  }
  a{
    color: $red-1;
  }
  p{
    font-size: slamp(16px, 24px);
  }
}
@include font-face("FuturaBT-Light", "../fonts/FuturaBT-Light", 300, normal);
@include font-face("Augustus", "../fonts/Augustus", 300, normal);

:root {
  font-size: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  font-family: $font-family-1;
  font-weight: $font-weight-base;
  letter-spacing: $font-spacing-base;
  line-height: $line-height-base;
  color: $text-base;
  font-size: slamp(16px, 18px);
}

/* Links */
@mixin underline-link(
$height: 1px, 
$color: inherit, 
$origin: bottom right, 
$back-origin: bottom left, 
$reverse: false,
$factor: 1,
$speed: $speed-medium,
$activeClass: 'active') {
  text-decoration: none;
  display: inline-block;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    border-bottom: $height solid;
    border-color: $color;
    bottom: 0;
    left: 0;
    transform-origin: $origin;
    transition: transform $speed ease;
    @if $reverse {
      transform: scaleX(0);
    } @else {
      transform: scaleX(1);
    }
  }
  @include react('pointer'){
    &:hover {
      &::after {
        transform-origin: $back-origin;
        @if $reverse {
          transform:  scaleX(1);
        } @else {
          transform: scaleX(0);
        }
      }
    }
  }
  &.#{$activeClass}:after {
    @if $reverse {
      transform: scaleX(1);
    } @else {
      transform: scaleX(0);
    }
  }
  
}
$defaults_react_breakpoints: (
  "small": (min-width: 320px), 
  "medium": (min-width: 750px),
  "large": (min-width: 1000px),
  "xlarge": (min-width: 1300px),
  "xxlarge": (min-width: 1600px),
  "pointer": "(pointer: fine) and (hover: hover)",
  "touch": "(pointer: coarse) and (hover: none)"
);

$react_breakpoints: ()!default;

/// Original mixins https://css-tricks.com/snippets/sass/mixin-manage-breakpoints/
/// @author Davide Marchet
/// @param {String} $query - Query name
/// @require $queries

@mixin react($query) {
  $merged: map-merge($defaults_react_breakpoints, $react_breakpoints);
  @if map-has-key($merged, $query) {
    $query: map-get($merged, $query);
    @if type-of($query) == string {
      @media #{unquote($query)} {
        @content;
      }
    } @else {
      @media #{inspect($query)} {
        @content;
      }
    }
  }
  @else {
    @warn "No value could be retrieved from `#{$query}`. "
        + "Available queries are: #{map-keys(map-merge($defaults_react_breakpoints, $react_breakpoints))}.";
  }
}

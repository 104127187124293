/* Utility */

@import 'scss-react/dist/index';
@import 'scss-slamp/dist/index';

/* @group Scss React */
// https://github.com/DidoMarchet/scss-react

/* stylelint-disable-next-line */
$react_breakpoints: (
  "<medium": (max-width: 749px),
  "<large":  (max-width: 999px),
  "mlarge":  (min-width: 1200px)
);

/*
$defaults_react_breakpoints: (
  "small": (min-width: 320px), 
  "medium": (min-width: 750px),
  "large": (min-width: 1000px),
  "xlarge": (min-width: 1300px),
  "xxlarge": (min-width: 1600px),
  "pointer": (pointer: fine) and (hover: hover),
  "touch": (pointer: coarse) and (hover: none)
);
*/

/* @end */

/* @group Scss Slamp */
// https://github.com/DidoMarchet/scss-slamp

/* stylelint-disable-next-line */
$slamp_limits: (
  "max": 1920px
);

/*
$defaults_slamp_limits: (
  "root-size": 16px, 
  "min": 480px, 
  "max": 1600px
);
*/

/* @end */

/* @group Generate Static Spacing */
@mixin generate-static-spacing($class, $prop, $limit: 50, $offset: 5) {
  $i: $offset;
  @while $i <= $limit {
    .#{$class}-#{$i} {
      #{$prop}: #{$i}px;
    }

    $i: $i + $offset;
  }
}

/* @end */
